<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
<!--    <div class="mg-bt-10">-->
<!--      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>-->
<!--    </div>-->
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            style="width: 100%"
            size="mini"
            @selection-change="handleSelectionChange">
      <el-table-column
              type="selection"
              align="center"
              width="55">
      </el-table-column>
      <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="100">
      </el-table-column>
      <el-table-column
              prop="name"
              label="姓名">
      </el-table-column>
      <el-table-column
          prop="mobile"
          label="联系电话">
      </el-table-column>
      <el-table-column
          prop="email"
          label="邮箱">
      </el-table-column>
      <el-table-column
          prop="company"
          label="公司名称">
      </el-table-column>
      <el-table-column
          prop="product"
          label="产品">
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注">
      </el-table-column>
      <el-table-column
              prop="create_time"
              label="提交时间"
              align="center"
              width="140">
      </el-table-column>
<!--      <el-table-column-->
<!--              fixed="right"-->
<!--              label="操作"-->
<!--              align="center"-->
<!--              width="100">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        pageSize: that.search.limit,
        keywords: that.search.keywords,
      }
      this.$api.merchant.messageIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
